<template>
  <div>
    <discount-fee-add-sidebar
      ref="add"
      :create-type="type"
      @refresh-discounts="fetchDiscounts()"
      @refresh-fees="fetchFees()"
    />

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-card
          no-body
        >
          <div class="d-flex m-2 align-items-center justify-content-between">
            <h4>
              Discounts
            </h4>
            <b-button
              variant="primary"
              @click="create(TYPE_DISCOUNT)"
            >Add</b-button>
          </div>
          <b-table
            :items="discounts"
            :fields="fields"
            responsive
            show-empty
            empty-text="No discounts found"
            :busy="loadingDiscounts"
          >
            <template v-slot:cell(name)="data">
              {{ data.item.discount_name }}
            </template>
            <template v-slot:cell(amount)="data">
              <template v-if="data.item.discount_percent != null">
                {{ data.item.discount_percent }}%
              </template>
              <template v-else>
                ${{ data.item.discount_dollar_amount }}
              </template>
            </template>
            <template v-slot:cell(actions)="data">
              <b-button
                variant="primary"
                size="sm"
                class="mr-1"
                @click="updateDiscount(data.item)"
              >
                Update
              </b-button>
              <b-button
                variant="danger"
                size="sm"
                @click="confirmRemove(data.item.discount_id, TYPE_DISCOUNT)"
              >
                Delete
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <b-card
          no-body
        >
          <div class="d-flex m-2 align-items-center justify-content-between">
            <h4>
              Fees
            </h4>
            <b-button
              variant="primary"
              @click="create(TYPE_FEE)"
            >Add</b-button>
          </div>

          <b-table
            :items="fees"
            :fields="fields"
            responsive
            show-empty
            empty-text="No fees found"
            :busy="loadingFees"
          >
            <template v-slot:cell(name)="data">
              {{ data.item.fee_name }}
            </template>
            <template v-slot:cell(amount)="data">
              <template v-if="data.item.fee_percent != null">
                {{ data.item.fee_percent }}%
              </template>
              <template v-else>
                ${{ data.item.fee_dollar_amount }}
              </template>
            </template>
            <template v-slot:cell(actions)="data">
              <b-button
                variant="primary"
                size="sm"
                class="mr-1"
                @click="updateFee(data.item)"
              >
                Update
              </b-button>
              <b-button
                variant="danger"
                size="sm"
                @click="confirmRemove(data.item.fee_id, TYPE_FEE)"
              >
                Delete
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <icon-modal
      ref="confirm"
      icon="XOctagonIcon"
      icon-classes="text-danger"
      @close="id = null"
    >
      <p class="text-center">
        Are you sure you would like to delete this {{ type }}?
      </p>
      <template v-slot:footer>
        <b-button
          variant="default"
          @click="$refs.confirm.close()"
        >Go Back</b-button>
        <spinner-button
          variant="danger"
          :loading="deleting"
          @click="processDelete()"
        >Delete</spinner-button>
      </template>
    </icon-modal>
  </div>
</template>

<script>
import store from '@/store'
import discountAndFeesStoreService from '@/services/discountAndFeesStoreService'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import DiscountFeeAddSidebar from '@/views/discountsAndFees/DiscountFeeAddSidebar.vue'
import {
  BButton,
  BCard,
  BTable,
  BRow,
  BCol,
} from 'bootstrap-vue'
import IconModal from '@/layouts/components/IconModal.vue'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'

export default {
  components: {
    DiscountFeeAddSidebar,
    IconModal,
    SpinnerButton,
    BButton,
    BCard,
    BTable,
    BRow,
    BCol,
  },
  data: () => ({
    fields: [
      {
        key: 'name',
      },
      {
        key: 'amount',
      },
      {
        key: 'actions',
      },
    ],
  }),
  setup() {
    // Constants
    const toast = useToast()
    const DISCOUNT_AND_FEE_STORE_KEY = 'app-discount-fee'
    const TYPE_DISCOUNT = 'discount'
    const TYPE_FEE = 'fee'

    // Store
    if (!store.hasModule(DISCOUNT_AND_FEE_STORE_KEY)) store.registerModule(DISCOUNT_AND_FEE_STORE_KEY, discountAndFeesStoreService)
    onUnmounted(() => {
      if (store.hasModule(DISCOUNT_AND_FEE_STORE_KEY)) store.unregisterModule(DISCOUNT_AND_FEE_STORE_KEY)
    })

    // Variables
    const loadingDiscounts = ref(false)
    const loadingFees = ref(false)
    const deleting = ref(false)
    const discounts = ref([])
    const id = ref(null)
    const fees = ref([])
    const type = ref('')

    // Refs
    const confirm = ref(null)
    const add = ref(null)
    const feeAdd = ref(null)

    // Methods
    const fetchDiscounts = () => {
      loadingDiscounts.value = true
      store.dispatch(`${DISCOUNT_AND_FEE_STORE_KEY}/fetchDiscounts`)
        .then(response => {
          discounts.value = response.data
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong fetching the discounts please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          loadingDiscounts.value = false
        })
    }
    const fetchFees = () => {
      loadingFees.value = true
      store.dispatch(`${DISCOUNT_AND_FEE_STORE_KEY}/fetchFees`)
        .then(response => {
          fees.value = response.data
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong fetching the fees please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => { loadingFees.value = null })
    }

    const fetch = () => {
      fetchDiscounts()
      fetchFees()
    }
    const confirmRemove = (selected, selectedType) => {
      id.value = selected
      type.value = selectedType
      confirm.value.open()
    }
    const processDelete = () => {
      deleting.value = true
      store.dispatch(`${DISCOUNT_AND_FEE_STORE_KEY}/delete${type.value === TYPE_DISCOUNT ? 'Discount' : 'Fee'}`, { id: id.value })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully deleted the discount',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          if (type.value === TYPE_DISCOUNT) {
            fetchDiscounts()
          } else {
            fetchFees()
          }
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong trying to delete this discount please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          confirm.value.close()
          deleting.value = false
        })
    }
    const updateDiscount = discount => {
      type.value = TYPE_DISCOUNT
      add.value.setDiscount(JSON.parse(JSON.stringify(discount)))
      add.value.open()
    }
    const updateFee = fee => {
      type.value = TYPE_FEE
      add.value.setFee(JSON.parse(JSON.stringify(fee)))
      add.value.open()
    }
    const create = addType => {
      type.value = addType
      add.value.open()
    }

    // initial
    fetch()

    return {
      loadingDiscounts,
      deleting,
      discounts,
      add,
      id,

      loadingFees,
      fees,
      feeAdd,

      create,
      type,
      confirm,

      TYPE_DISCOUNT,
      TYPE_FEE,

      fetch,
      fetchDiscounts,
      fetchFees,
      confirmRemove,
      processDelete,
      updateDiscount,
      updateFee,
    }
  },
}
</script>
