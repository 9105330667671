<template>
  <b-sidebar
    v-model="showSidebar"
    shadow
    backdrop
    no-header
    sidebar-class="sidebar-lg"
    bg-variant="white"
    right
    @hidden="resetForm()"
  >
    <b-card-body>
      <validation-observer
        ref="refFormObserver"
        #default="{ handleSubmit }"
      >
        <b-form
          @submit.prevent="handleSubmit(submit)"
        >
          <validation-provider
            #default="validationContext"
            :name="`${createType} name`"
            rules="required|min:2"
          >
            <b-form-group
              :label="`${label} Name`"
            >
              <b-form-input
                v-model="item.name"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            :name="`${createType} type`"
            rules="required"
          >
            <b-form-group :label="`${label} Type`">
              <v-select
                v-model="type"
                :options="types"
                :reduce="val => val.key"
                class="form-control p-0 border-control"
                :class="getValidationStateNonBootstrap(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <template v-if="type === TYPE_PERCENT">
            <validation-provider
              #default="validationContext"
              :name="`${createType} percent`"
              rules="required|min_value:0.01|max_value:100"
            >
              <b-form-group
                :label="`${label} Percent`"
              >
                <b-input-group>
                  <cleave
                    v-model="item.percent"
                    class="form-control"
                    :class="getValidationStateNonBootstrap(validationContext)"
                    :options="percentOptions"
                  />

                  <b-input-group-text slot="append">
                    %
                  </b-input-group-text>
                </b-input-group>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </template>

          <template v-else-if="type === TYPE_DOLLAR_AMOUNT">
            <validation-provider
              #default="validationContext"
              name="discount dollar amount"
              rules="required|min_value:1"
            >
              <b-form-group
                label="Discount Dollar Amount"
              >
                <b-input-group>
                  <b-input-group-text slot="prepend">
                    $
                  </b-input-group-text>

                  <cleave
                    v-model="item.dollar_amount"
                    :options="dollarOptions"
                    class="form-control"
                    :class="getValidationStateNonBootstrap(validationContext)"
                  />
                </b-input-group>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </template>

          <div class="d-flex mt-2">
            <spinner-button
              variant="primary"
              type="submit"
              class="mr-2"
              :loading="submitting"
            >
              Save
            </spinner-button>
            <b-button
              @click="close()"
            >
              Go Back
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupText,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, computed } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
import Cleave from 'vue-cleave-component'
import { required, minValue, maxValue } from '@validations'

export default {
  components: {
    BSidebar,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupText,

    vSelect,
    SpinnerButton,
    Cleave,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    createType: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    percentOptions: {
      numeral: true,
      numeralPositiveOnly: true,
    },
    dollarOptions: {
      numeral: true,
      numeralPositiveOnly: true,
      numeralDecimalScale: 0,
    },
    required,
    minValue,
    maxValue,
  }),
  setup(props, { emit }) {
    // Constants
    const DISCOUNT_AND_FEE_STORE_KEY = 'app-discount-fee'
    const TYPE_PERCENT = 'percent'
    const TYPE_DOLLAR_AMOUNT = 'dollar_amount'
    const toast = useToast()

    // Variables
    const showSidebar = ref(false)
    const updateId = ref(null)
    const blankItem = {
      name: null,
      percent: null,
      dollar_amount: null,
    }
    const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const type = ref(null)
    const types = [
      {
        key: TYPE_PERCENT,
        label: 'Percent',
      },
      {
        key: TYPE_DOLLAR_AMOUNT,
        label: 'Dollar Amount',
      },
    ]
    const submitting = ref(false)

    // Computed
    const label = computed(() => props.createType.slice(0, 1).toUpperCase() + props.createType.slice(1))

    // Functions
    const open = () => {
      showSidebar.value = true
    }
    const close = () => {
      showSidebar.value = false
    }
    const reset = () => {
      item.value = JSON.parse(JSON.stringify(blankItem))
      updateId.value = null
      type.value = null
    }
    const submit = () => {
      const request = {
        name: item.value.name,
      }

      if (type.value === TYPE_PERCENT) {
        request.percent = item.value.percent
      } else {
        request.dollar_amount = item.value.dollar_amount
      }

      submitting.value = true
      const action = updateId.value != null ? `update${label.value}` : `add${label.value}`
      const params = updateId.value != null ? { id: updateId.value, request } : request

      store.dispatch(`${DISCOUNT_AND_FEE_STORE_KEY}/${action}`, params)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: `Successfully ${updateId.value != null ? 'updated' : 'created'} this ${props.createType}`,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })

          emit(`refresh-${props.createType}s`)
          close()
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: `Something went wrong saving this ${props.createType} please try again later`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          submitting.value = false
        })
    }
    const setDiscount = d => {
      item.value = {
        name: d.discount_name,
        percent: d.discount_percent,
        dollar_amount: d.discount_dollar_amount,
      }

      updateId.value = d.discount_id
      type.value = item.value.percent != null ? TYPE_PERCENT : TYPE_DOLLAR_AMOUNT
    }
    const setFee = f => {
      item.value = {
        name: f.fee_name,
        percent: f.fee_percent,
        dollar_amount: f.fee_dollar_amount,
      }

      updateId.value = f.fee_id
      type.value = item.value.percent != null ? TYPE_PERCENT : TYPE_DOLLAR_AMOUNT
    }

    // Validation
    const {
      resetForm, refFormObserver, getValidationState, getValidationStateNonBootstrap,
    } = formValidation(reset)

    return {
      showSidebar,
      item,
      type,
      types,
      submitting,

      label,

      TYPE_DOLLAR_AMOUNT,
      TYPE_PERCENT,

      resetForm,
      refFormObserver,
      getValidationState,
      getValidationStateNonBootstrap,

      open,
      close,
      submit,
      setDiscount,
      setFee,
    }
  },
}
</script>

<style lang="scss" scoped>
.border-control::v-deep .vs__dropdown-toggle {
  border: 0;
  border-radius: 0;
}
</style>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
